import AirportSelectFrom from 'app/components/Flight/AirportSelectFrom';
import AirportSelectTo from 'app/components/Flight/AirportSelectTo';
import TripTypes from 'app/components/Flight/BaseInputs/TripTypes';
import DatePickerFrom from 'app/components/Flight/DatePickerFrom';
import DatePickerTo from 'app/components/Flight/DatePickerTo';
import { companies } from 'app/components/Flight/FlightCompanies';
import PricingTypes from 'app/components/Flight/PricingTypes';
import PassengerCounter from 'app/components/Flights/FilterForm/PassengerCounter';
import classnames from 'classnames';
import dynamic from 'next/dynamic';
import { FC, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { sendDataToPassthruForm } from '../../../../actions/baseActions';
import {
	doSearchPartners,
	prePopulateDataFromQueryString,
	submitFormBack,
	submitFormFront,
} from '../../../../actions/flightActions';
import Helper from '../../../modules/Helper';
import Checkbox from '../../Common/Checkbox';
import CompanyImage from '../../Common/CompanyImage';
import TooltipError from '../../Common/TooltipError';
import ModalSignUp from '../../PriceAlert/ModalSignUp';

import DoubleArrow from '@images/double-arrow.svg';
import Ellipse from '@images/loading-spinner.svg';
import { IAirportItem } from 'app/components/Common/AirportSelect/Item';
import { ItemType } from 'app/components/Common/InputDropDown';
import InputPassengers from 'app/components/Flight/InputPassengers';
import InputPricingTypes from 'app/components/Flight/InputPricingTypes';
import Confirmation from 'app/components/PriceAlert/Confirmation';
import { SelectItem, TripType } from 'app/modules/Constants';
import { format } from 'date-fns';

const PriceAlertCheckboxConfirmation = dynamic(() => import('../../PriceAlert/PriceAlertCheckboxConfirmation'), {
	ssr: false,
});

const PARTNER_LIMIT = 4;
const PARTNER_DISPLAY_LIMIT = 10;
type PartnerType = { logoUrl: string | undefined; deepLink: any };

interface KCFilterFormProps {
	priceAlertHandlerChecked: any;
	compareResult: any;
	uid: any;
	imid: any;
	compareData: any;
	openedInput: unknown;
	brandsDescription: string;
	selectedTripType: ItemType;
	selectedFromDate: Date;
	selectedToDate: Date;
	selectedFromLocation: IAirportItem;
	selectedToLocation: IAirportItem;
	selectedPricingType: SelectItem;
	numChildren: number;
	numAdults: number;
	isUserLoggedIn: boolean;
	isSigninCheckboxChecked: boolean;
	submitFormBack: (arg0: any) => void;
	doSearchPartners: (arg0: boolean) => void;
	submitFormFront: () => void;
}

const KCFilterForm: FC<KCFilterFormProps> = (props) => {
	const { t } = useTranslation();

	const {
		selectedFromDate,
		selectedToDate,
		selectedFromLocation,
		selectedToLocation,
		selectedPricingType,
		numChildren,
		numAdults,
		isUserLoggedIn,
		isSigninCheckboxChecked,
	} = props;

	const getSearchButtonText = () => {
		return props.priceAlertHandlerChecked ? 'keywords.signUpAndSearch' : 'main.flights.searchBtn';
	};

	const arePartnersLoading = useMemo(() => {
		return props.compareResult === null;
	}, [props.compareResult]);

	const arePartnersLoaded = useMemo(() => {
		return props.compareResult?.kayakResponse != null;
	}, [props.compareResult]);

	const partners = useMemo(() => {
		const items = props.compareResult?.kayakResponse?.compareToItems || [];
		return items.slice(0, PARTNER_DISPLAY_LIMIT);
	}, [props.compareResult]);

	const domain = useMemo(() => {
		return props.compareResult?.kayakDomain;
	}, [props.compareResult]);

	const preparedPartners = useMemo(() => {
		const makeLink = (link: string) => {
			if (link.startsWith('http')) {
				return link;
			}
			return 'https://' + domain + link;
		};
		if (!arePartnersLoaded) {
			return null;
		}
		return partners.map((partner: { deepLink: any; logoUrl: any }) => {
			return {
				...partner,
				deepLink: makeLink(partner.deepLink),
				logoUrl: makeLink(partner.logoUrl),
			};
		});
	}, [arePartnersLoaded, partners, domain]);

	const [partnerCheckboxStatuses, setPartnerCheckboxStatuses] = useState<boolean[]>([]);

	useEffect(() => {
		if (arePartnersLoading) {
			setPartnerCheckboxStatuses([]);
		}
		if (arePartnersLoaded) {
			setPartnerCheckboxStatuses(partners.map(() => false));
		}
	}, [arePartnersLoading, arePartnersLoaded]);

	const checkChoiceCountLimit = useMemo(() => {
		const trueCount = partnerCheckboxStatuses.filter((status) => status).length;
		return trueCount >= PARTNER_LIMIT;
	}, [partnerCheckboxStatuses]);

	const setPartnerChecked = (partnerId: number) => {
		setPartnerCheckboxStatuses((prev) => {
			const newStatuses = [...prev];
			newStatuses[partnerId] = !prev[partnerId];
			if (checkChoiceCountLimit && newStatuses[partnerId]) {
				return prev;
			}
			return newStatuses;
		});
	};

	const searchId = useMemo(() => {
		return props.compareResult?.kayakResponse?.searchId;
	}, [props.compareResult]);

	const cluster = useMemo(() => {
		const cookies = props.compareResult?.kayakCookies;

		if (!cookies) {
			return null;
		}
		const k = 'cluster=';
		const clusterCookie = cookies.find((cookie: string) => cookie.includes(k));

		return clusterCookie?.split(k)[1][0];
	}, [props.compareResult?.kayakCookies]);

	const generateCompareUrlMultiSelect = (indexes: number[]) => {
		const providerCodes = indexes.map((index) => partners[index].providerCode);
		const joinedProviderCodes = providerCodes.join(',');
		const showOn = 'panel';
		const requestParams = {
			providerCodes: joinedProviderCodes,
			showOn,
			searchId,
		};
		const iframeUrl = '/providers/simple-window?' + Helper.serialize(requestParams);
		const affliateId = 'kan_243435_561323';
		const p = `${props.uid}${props.imid}`;
		const fullUrl = `https://www.kayak.com/in?a=${affliateId}&cluster=${cluster}&p=${p}&url=${iframeUrl}`;
		return fullUrl;
	};

	const openInNewTab = (url: string) => {
		window.open(url);
	};

	const goToResultPage = (checkboxes: any[]) => {
		let indexes = checkboxes
			.map((checked: any, index: any) => (checked ? index : null))
			.filter((index: null) => index !== null);

		if (!indexes.length) {
			const partnersCount = partners.length;
			indexes = [0, 1, 2, 3].slice(0, partnersCount);
		}

		const parsed = props.compareData;
		const newData = {
			...parsed,
			ids: indexes,
		};
		const redirectUrl = generateCompareUrlMultiSelect(indexes);

		if (Helper.isKayakCompareFlowFront()) {
			console.log('Opening new tab with data: ', newData);
			openInNewTab(redirectUrl);
			console.log('submitting form back');
			props.submitFormBack(parsed);
		} else if (Helper.isKayakCompareFlowBack()) {
			console.log('Redirecting to compare-result: ', newData);
			window.location.href = redirectUrl;
		}
	};

	const searchPartners = () => {
		const isKcForm = true;
		window.scrollTo(0, 0);
		props.doSearchPartners(isKcForm);

		if (!Helper.isKayakCompareFlowFront()) {
			props.submitFormFront();
		}
	};

	const handleCompareAll = () => {
		const statuses = partnerCheckboxStatuses.map(() => true);
		goToResultPage(statuses);
	};

	const isMobile = Helper.isMobile();
	const isOneWay = props.selectedTripType.value === TripType.ONE_WAY;
	const showForm = isMobile ? !arePartnersLoaded : true;
	const showPartners = isMobile ? arePartnersLoaded : true;
	const showNewsletterCriteria = !(isUserLoggedIn && isSigninCheckboxChecked);

	return (
		<div className="container">
			<div id="kayak-compare-filter-form" className="mx-auto">
				{arePartnersLoading && isMobile && (
					<div className="search-overlay">
						<Ellipse className="kc-loading-spinner" />
					</div>
				)}
				<div className="row">
					{isMobile && arePartnersLoaded && (
						<div className="flight-info">
							<div className="flight-info__location">
								{selectedFromLocation.iata} <DoubleArrow className="mx-2" /> {selectedToLocation.iata}
							</div>
							<div className="d-flex flight-info__data">
								<div className="d-flex">
									<div className="d-flex flex-column me-2">
										<span className="flight-info__date">{format(selectedFromDate, 'MMM d')}</span>
										<span className="flight-info__day">{format(selectedFromDate, 'EEEE')}</span>
									</div>
									<span className="mx-2">—</span>
									<div className="d-flex flex-column ms-2">
										<span className="flight-info__date">{format(selectedToDate, 'MMM d')}</span>
										<span className="flight-info__day">{format(selectedToDate, 'EEEE')}</span>
									</div>
								</div>
								<div className="d-flex">
									<div className="d-flex flex-column me-3">
										{<span className="flight-info__pricing-type">{selectedPricingType.value}</span>}
										<span>Class</span>
									</div>
									<div className="d-flex flex-column">
										<span className="flight-info__travelers">{numAdults + numChildren}</span>
										<span>{t('keywords.travelers')}</span>
									</div>
								</div>
							</div>
						</div>
					)}
					{showForm && (
						<>
							<div className="inline-inputs" id="base-inputs">
								<TripTypes />
								<div className="input d-none d-xl-block">
									<PricingTypes />
								</div>
								<div className="input d-none d-xl-block">
									<PassengerCounter name="passengers-counter" placeholder={t('keywords.passengers')} />
								</div>
							</div>
							<div className="col-12 col-md-7" id="parent-filter">
								<div id="filter-form" className="row justify-content-center">
									<br className="d-xl-block d-none" />
									<form
										id="redirect-back-form"
										method="post"
										action={process.env.NEXT_PUBLIC_BACKEND_URL + '/flights/redirect'}
									/>
									<div className="px-3 md-px-4">
										<div>
											<TooltipError flexOne name="airport-from" label={isMobile ? t('keywords.from') : undefined}>
												<AirportSelectFrom name="airport-from" />
											</TooltipError>
										</div>
										<div>
											<TooltipError flexOne name="airport-to" label={isMobile ? t('keywords.to') : undefined}>
												<AirportSelectTo name="airport-to" placeholder="Enter and select a destination" />
											</TooltipError>
										</div>
										<div className="row">
											<div
												className={classnames('col-6', {
													'col-12': isOneWay,
												})}
											>
												<TooltipError flexOne name="date-from" label={isMobile ? t('keywords.departing') : undefined}>
													<DatePickerFrom name="date-from" />
												</TooltipError>
											</div>
											{!isOneWay && (
												<div className="col-6">
													<TooltipError flexOne name="date-to" label={isMobile ? t('keywords.returning') : undefined}>
														<DatePickerTo name="date-to" />
													</TooltipError>
												</div>
											)}
										</div>
										<div className="signup-confirmation">
											<div className="d-xl-block d-none mt-2">
												<PriceAlertCheckboxConfirmation />
											</div>
										</div>
										<div className="row main-inputs d-xl-none">
											<div className="d-xl-none  col-6">
												<TooltipError
													labelClass="d-block d-xl-none"
													label={t('keywords.class')}
													name="input-pricing-types"
												>
													<InputPricingTypes name="input-pricing-types" />
												</TooltipError>
											</div>

											<div className="d-xl-none col-6">
												<TooltipError
													labelClass="d-block d-xl-none"
													label={t('keywords.travelers')}
													name="mobile-input-passengers"
												>
													<InputPassengers name="mobile-input-passengers" />
												</TooltipError>
											</div>
											{showNewsletterCriteria && (
												<div className="d-xl-none d-block my-2">
													<Confirmation onlyMobile showCheckbox />
												</div>
											)}
										</div>
										<div id="search-button-container" className="col-12 mt-3">
											<button
												id="search-button"
												type="button"
												onClick={() => searchPartners()}
												className={classnames('btn', {
													'price-alert-handler-checked': props.priceAlertHandlerChecked,
												})}
											>
												{t(getSearchButtonText())}
											</button>
										</div>
									</div>
									{Helper.isDesktop() && <ModalSignUp />}
								</div>
							</div>
						</>
					)}

					{showPartners && (
						<div
							className={classnames('col-12 col-md-5 brands-section px-md-4 pb-4', {
								'brands-selection': arePartnersLoaded,
							})}
						>
							{arePartnersLoaded ? (
								<div>
									<p className="mt-2 compare-text text-align-center">
										{preparedPartners.length > 4 ? t('compare.choose') : t('compare.chooseAll')}
									</p>
									{preparedPartners.map((partner: PartnerType, key: number) => (
										<div
											key={key}
											className={classnames('company-item mb-2 d-flex justify-content-between', {
												'company-item--white-bg': isMobile,
												'company-item--selected': !!partnerCheckboxStatuses[key],
												'justify-content-start': preparedPartners.length > 4,
											})}
										>
											{preparedPartners.length > 4 && (
												<div className="col-1 c-checkbox-compare">
													<Checkbox
														id={`compare-${key}-checkbox`}
														checked={!!partnerCheckboxStatuses[key]}
														onChange={() => setPartnerChecked(key)}
														disabled={checkChoiceCountLimit && !partnerCheckboxStatuses[key]}
													/>
												</div>
											)}

											<div
												className={classnames('d-flex justify-content-between col-11', {
													'col-12': preparedPartners.length <= 4,
												})}
											>
												<div onClick={() => setPartnerChecked(key)} className="col-6">
													<div className="comp-logo">
														<img src={partner.logoUrl} className="w-100" />
													</div>
												</div>
												<Button
													className={classnames('btn btn--yellow btn-link', {
														'btn--transparent col-4 ms-auto': isMobile,
														'col-5': !isMobile,
													})}
													style={{ cursor: 'pointer' }}
													onClick={() => openInNewTab(partner.deepLink)}
												>
													{t('keywords.view')}
												</Button>
											</div>
										</div>
									))}
									<div id="compare-button-container" className="mt-4">
										{preparedPartners.length > 4 ? (
											<Button
												id="compare-button"
												type="button"
												onClick={() => goToResultPage(partnerCheckboxStatuses)}
												className={classnames('btn btn--yellow btn--compare w-100 mb-3', {
													'price-alert-handler-checked': props.priceAlertHandlerChecked,
													disabled: !partnerCheckboxStatuses.some((status) => status),
												})}
											>
												{partnerCheckboxStatuses.filter((status) => status).length > 0
													? t('compare.compareBtn', {
															count: partnerCheckboxStatuses.filter((status) => status).length,
													  })
													: t('keywords.compare')}
											</Button>
										) : (
											<Button className="btn btn-blue md-w-100" onClick={handleCompareAll}>
												Compare All
											</Button>
										)}
									</div>
								</div>
							) : (
								<>
									<h6 className="text-center">{props.brandsDescription}</h6>
									<div className="text-center justify-content-center row d-none d-md-flex ">
										{arePartnersLoading ? (
											<Ellipse className="kc-loading-spinner" />
										) : (
											companies.map((companyImage, key) => {
												return (
													<div key={key} className="col-2 brand-img">
														<CompanyImage src={companyImage} jumpToInputName="airport-to" />
													</div>
												);
											})
										)}
									</div>
								</>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	priceAlertHandlerChecked: state.base.priceAlertHandlerChecked,
	selectedTripType: state.flight.selectedTripType,
	openedInput: state.base.openedInput,
	countryCode: state.base.countryCode,
	compareResult: state.compare.result,
	compareData: state.compare.data,
	enabledVert: state.base.enabledVert,
	uid: state.base.uid,
	imid: state.base.imid,
	selectedFromDate: state.flight.selectedFromDate,
	selectedToDate: state.flight.selectedToDate,
	selectedFromLocation: state.flight.selectedFromLocation,
	selectedToLocation: state.flight.selectedToLocation,
	selectedPricingType: state.flight.selectedPricingType,
	numChildren: state.flight.countChildren,
	numAdults: state.flight.countAdults,
	isSigninCheckboxChecked: state.base.signinCheckbox,
	isUserLoggedIn: state.base.isUserLoggedIn,
});

export default connect(mapStateToProps, {
	doSearchPartners,
	prePopulateDataFromQueryString,
	submitFormBack,
	submitFormFront,
	sendDataToPassthruForm,
})(KCFilterForm);
