import { FC } from 'react';
import FlightCompanies from './FlightCompanies';

type NewMainAreaProps = {
	brandsDescription: string;
};

const NewMainArea: FC<NewMainAreaProps> = ({ brandsDescription }) => {
	return (
		<div id="new-main-area">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-10 col-xl-7 mt-md-5 mt-0">
						<div className="text-center">
							<FlightCompanies description={brandsDescription} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewMainArea;
