import Trans from 'app/components/Common/Trans';
import KCFilterForm from 'app/components/Flights/KCFilterForm/KCFilterForm';
import { TFunction } from 'i18next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Image from 'next/image';
import React from 'react';
import AdSense from 'react-adsense';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Helper from '../../modules/Helper';
import Button from '../Common/Button';
import CallIcon from '../Common/Icons/CallIcon';
import VertTitle from '../Common/VertTitle';
import NewMainArea from '../Flight/NewMainArea';
import MainLayout from '../Layouts/MainLayout';
import FilterForm from './FilterForm';

type FlightsProps = {
	query?: any;
	dynamic?: boolean;
	useHeadline?: boolean;
	location?: string;
	page?: any;
	uid?: string;
	imid?: string;
	lng: string;
	userCountry: string;
	isMobile: boolean;
	isSigninCheckboxChecked?: boolean;
	isUserLoggedIn?: boolean;
	t: TFunction;
};
type FlightsState = {
	isVan: boolean;
	vertTitle: string;
	description: string;
	brandsDescription: string;
	h2description: string;
	metaDescription: string;
	cityTitle: string;
	isLocation: boolean;
	slogan: string;
	cityMetaDescription?: string;
	cityKeywords?: string;
	metaTitle: string;
	newsletterSlogan: string;
	url: string;
	isMobile: boolean;
};

const MainContent = dynamic(() => import('../Common/MainContent'), { ssr: false });
const NewsLetterForm = dynamic(() => import('../Flight/NewsLetterForm'), { ssr: false });

class Flights extends React.Component<FlightsProps, FlightsState> {
	constructor(props: FlightsProps) {
		super(props);
		this.state = {
			isVan: false,
			vertTitle: 'mainArea.flights.title',
			brandsDescription: 'mainArea.flights.brandsDescription',
			description: ``,
			h2description: ``,
			metaDescription: props.page?.metaDescription || ``,
			cityTitle: ``,
			isLocation: false,
			slogan: `main.flights.verticalSlogan`,
			metaTitle: props.page?.title || this.props.t(`main.flights.metaTitle`),
			newsletterSlogan: this.props.t(`main.flights.newsletterSlogan`),
			url: props.page
				? `https://smarterflyer.com/${props.page.slug}${props.page?.lng ? `?lng=${props.page.lng}` : ''}`
				: 'https://smarterflyer.com/',
			isMobile: false,
		};
	}

	componentDidMount() {
		if (this.props.page?.headingOne && this.props.page?.headingTwo) {
			this.setState({
				vertTitle: this.props.page.headingOne,
				brandsDescription: this.props.page.headingTwo,
			});
			return;
		}
		const texts = this.props.query?.texts;
		if (texts) {
			this.setState({
				metaDescription: texts.metaDescription,
				metaTitle: texts.metaTitle,
				vertTitle: texts.h1,
				brandsDescription: texts.h2,
				newsletterSlogan: texts.newsletter,
			});
		}
	}

	renderAdSense = () => {
		if (typeof window != 'undefined') {
			if (process.env.NEXT_PUBLIC_SHOW_PRODUCTION_SCRIPTS === 'true' && Helper.checkQueryStringKeyValue('ad', '1')) {
				return (
					<>
						<AdSense.Google
							client="ca-pub-2732183997877262"
							slot="2446285810"
							style={{ display: 'block' }}
							format="auto"
							responsive="true"
						/>
						<br />
					</>
				);
			}
		}
		return '';
	};

	renderBaseForm = () => {
		return (
			<div className="container">
				<div className="row justify-content-center align-items-center">
					<div className="cars-filter-form col-md-11">
						<div className="row">
							<div className="col-md">
								<VertTitle useHeadline={this.props.useHeadline} i18nKey={this.state.vertTitle} />
								<FilterForm />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	kcForm = () => {
		return (
			<>
				<VertTitle useHeadline={this.props.useHeadline} i18nKey={this.state.vertTitle} />
				<KCFilterForm brandsDescription={this.props.t('main.flights.kcBrandsDescription') as string} />
			</>
		);
	};

	render() {
		const { uid, imid, lng, userCountry, isSigninCheckboxChecked, isUserLoggedIn } = this.props;
		const showNewsletterCriteria = !(isUserLoggedIn && isSigninCheckboxChecked);
		const allowedCountries = ['US', 'CA'];
		const showPhone = (allowedCountries.includes(userCountry) && lng === 'en') || lng === 'es';
		const phoneNumber = lng === 'es' ? '858-633-8912' : '650 513-8248';
		const showKcForm = !Helper.isMobile() && (Helper.isKayakCompareFlowFront() || Helper.isKayakCompareFlowBack());

		const renderForm = () => {
			return showKcForm ? this.kcForm() : this.renderBaseForm();
		};

		return (
			<MainLayout>
				<Head>
					<script
						type="application/ld+json"
						dangerouslySetInnerHTML={{
							__html: `
								{
										"@context": "http://schema.org",
										"@type": "Service",
										"name": "${this.state.metaTitle}",
										"description" : "${this.state.metaDescription}",
										"url" :"${this.state.url}",
										"image" : "https://smarterflyer.com/images/block-background-cars.png"
								}`,
						}}
					/>
				</Head>
				<div id="main-area" className="cars-container">
					{renderForm()}
					<NewMainArea brandsDescription={this.state.brandsDescription} />
					{showPhone && (
						<div className="phone-call-section ">
							<div className="type-one">
								<div className="content">
									<div className="content-top">
										<Image src="/images/discount-1.png" width={70} height={70} alt="discount logo" />
										<div className="text">{Helper.trans('phoneSection.phoneCall')}</div>
									</div>

									<Button
										className="btn btn-warning call-button"
										id="smarterflyer_bottom_phone_button"
										href={`tel:${phoneNumber}`}
										uid={uid}
										imid={imid}
										isLink={true}
									>
										{Helper.trans('phoneSection.callNow')}
										<div className="call-icon">
											<CallIcon width={18} height={19} color="#000" />
										</div>
									</Button>
									<div className="content-bottom">
										Powered by <span className="powered-by">{Helper.trans('phoneSection.partnerPoweredBy')}</span>
									</div>
								</div>
							</div>
						</div>
					)}
					<div id="image-curve">
						<div className="row justify-content-center banner-text" style={{ width: '100%' }}>
							<div className="col-md-8">
								<Trans i18nKey="main.flights.curveText" />
							</div>
						</div>
					</div>
				</div>
				<MainContent />

				{showNewsletterCriteria && (
					<NewsLetterForm>
						<h5
							dangerouslySetInnerHTML={{
								__html: this.state.newsletterSlogan,
							}}
						/>
					</NewsLetterForm>
				)}
			</MainLayout>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		lng: state.base.lng,
		uid: state.base.uid,
		imid: state.base.imid,
		userCountry: state.base.countryCode,
		isMobile: state.base.isMobile,
		isSigninCheckboxChecked: state.base.signinCheckbox,
		isUserLoggedIn: state.base.isUserLoggedIn,
	};
};

export default withTranslation()(connect(mapStateToProps, {})(Flights));
